import React from 'react';
import { DropdownContentProps } from '../../DropdownContent/types';
import { UseMenuContextProps } from './types';

export const DropdownMenuContext = React.createContext<
    (UseMenuContextProps & Pick<DropdownContentProps, 'fullWidth' | 'goExperimental'>) | null
>(null);

const useMenuContext = () => {
    const context = React.useContext(DropdownMenuContext);

    if (!context) {
        throw new Error('Menu compound components cannot be rendered outside the DropdownMenu component');
    }

    return context;
};

export default useMenuContext;
