import React from 'react';
import { PopoverContent } from '@fiverr-private/popover';
import { useElementSize } from '@fiverr-private/hooks';
import { Container } from '@fiverr-private/layout_components';
import type { Types } from '@fiverr-private/theme';
import { DropdownMenuContext } from '../DropdownMenu/useMenuContext';
import useDropdownContext from '../Dropdown/useDropdownContext';
import type { DropdownPopoverContentProps } from '../Dropdown/types';
import type { DropdownContentProps } from './types';
import useContent from './useContent';

const DropdownContent = ({
  children,
  appendToBody,
  disableExitAnimation = false,
  fullWidth,
  closeOnClickOutside,
  zIndex,
  goExperimental = false,
}: DropdownContentProps & DropdownPopoverContentProps) => {
  const { searchWhenClosed, anchorRef } = useDropdownContext();
  const content = useContent();
  const { width: anchorWidth } = useElementSize(anchorRef.current);
  const getStylingProps = (goExperimental: boolean): Types.ContainerProps => ({
    boxShadow: 'aa',
    paddingY: '2',
    borderRadius: goExperimental ? '2xl' : 'lg',
    ...(goExperimental ? { background: '#0C0C0D' } : { backgroundColor: 'white' }),
    ...(goExperimental && {
      borderStyle: 'solid',
      borderColor: 'white_20',
      borderWidth: 'sm',
    }),
  });

  return (
    <PopoverContent
      disablePadding
      renderWhenClosed={searchWhenClosed}
      disableBoxShadow
      appendToBody={appendToBody}
      animationType="slideFade"
      onAnimationStart={content.onAnimationStart}
      disableAutoFocus={true}
      minWidth={anchorWidth}
      disableExitAnimation={disableExitAnimation}
      closeOnClickOutside={closeOnClickOutside}
      zIndex={zIndex}
      backgroundColor={goExperimental ? 'transparent' : undefined}
    >
      <DropdownMenuContext.Provider value={{ ...content, fullWidth, goExperimental }}>
        <Container {...getStylingProps(goExperimental)} ref={content.contentRef}>
          {children}
        </Container>
      </DropdownMenuContext.Provider>
    </PopoverContent>
  );
};

DropdownContent.id = 'DropdownContent';
DropdownContent.displayName = 'DropdownContent';

export default DropdownContent;
