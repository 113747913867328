import React, { useRef, useState } from 'react';
import { usePopoverContext } from '@fiverr-private/popover';
import { DropdownProps, MenuItem, MenuItems } from '../types';
import { DropdownContext } from '../useDropdownContext';
import useDropdownState from '../useDropdownState';

const DropdownContextProvider = ({
  children,
  onChange,
  values,
  defaultValues,
  searchWhenClosed,
  keyboardNavigationLoop,
  searchOnKeyPress,
  selectedIndicatorPosition,
}: DropdownProps) => {
  const [focusedItem, setFocusedItem] = useState<MenuItem['ref']>({} as MenuItem['ref']);
  const state = useDropdownState({ onChange, values, defaultValues });
  const anchorRef = useRef<HTMLElement>(null);
  const headerActionsRef = useRef<HTMLElement>(null);
  const items = useRef<MenuItems>([]).current;
  const isMultiSelect = useRef(null);

  const { setOpen } = usePopoverContext();
  const popoverContext = {
    setOpen,
  };

  return (
    <DropdownContext.Provider
      value={{
        ...state,
        searchWhenClosed,
        anchorRef,
        items,
        focusedItem,
        setFocusedItem,
        keyboardNavigationLoop,
        isMultiSelect,
        searchOnKeyPress,
        selectedIndicatorPosition,
        popoverContext,
        headerActionsRef,
      }}
    >
      {children}
    </DropdownContext.Provider>
  );
};

export default DropdownContextProvider;
