import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import Link from '../Links/Link';

import { fiverrGoText } from './FiverrGo.styles.ve.css';

export const FiverrGoAnimated = () => {
    const href = pathfinder('seller_ai_hub_page');
    const text = translate('header.try_fiverr_go');

    return (
        <Link className={`${fiverrGoText}`} href={href}>
            {text}
        </Link>
    );
};
