import PropTypes from 'prop-types';

const If = ({ condition, children }) => {
    if (!condition) {
        return null;
    }

    return children;
};

If.propTypes = {
    condition: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default If;
